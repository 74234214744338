import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    loginFooter: {
        padding: '10px',
        color: theme.custom.accentDark[0.7],
        position: 'fixed',
        bottom: '0',
        width: '100%',
    },
}));

const APP_VERSION = `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_GITHUB_RUN_NUMBER}`;

const AppInfo = () => {
    const classes = useStyles();

    // display app info at the footer of the login screen
    // EXAMPLE: "Version 1.1.0-497"
    const APP_INFO_TEXT = `Version ${APP_VERSION}`;

    return (
        <footer className={classes.loginFooter}>
            <span data-cy="appInfo-version">{APP_INFO_TEXT}</span>
        </footer>
    );
};

export default AppInfo;
